import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';
import { css } from 'styled-jsx/css';

const { mobile } = BREAKPOINT;

const styles = css`
  .form-input {
    margin-bottom: 20px;
    position: relative;
    text-align: left;
    @media screen and (max-width: ${mobile}px) {
      margin-bottom: 15px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .form-input-inner {
      letter-spacing: 0.02em;
      .form-label {
        font-size: 0.875rem;
        color: ${COLORS.boulder};
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 8px;
        @media screen and (max-width: ${mobile}px) {
          display: block;
        }
        em {
          display: inline-block;
          vertical-align: middle;
          color: ${COLORS.redError};
          font-style: normal;
          font-size: 10px;
          text-align: center;
          margin-left: 10px;
          position: relative;
          top: -1px;
          padding: 2px 5px;
          border: 1px solid ${COLORS.redError};
          box-sizing: border-box;
          border-radius: 10.5px;
        }
      }
      .input-wrapper {
        position: relative;
        .input-icon {
          position: absolute;
          left: 16px;
          top: 0;
          bottom: 0;
          width: 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        input {
          font-size: 1rem;
          color: ${COLORS.black2};
          border: 1px solid ${COLORS.black30};
          width: 100%;
          padding: 12px 16px;
          border-radius: 8px;
          transition: border 0.2s ease;
          &[type='text'] {
            -webkit-appearance: none;
          }
          &[type='number']::-webkit-outer-spin-button,
          &[type='number']::-webkit-inner-spin-button {
            -webkit-appearance: none;
            -moz-appearance: textfield;
            margin: 0;
          }
          ::placeholder {
            color: ${COLORS.black30};
          }
          &.is-error {
            border-color: ${COLORS.redError};
          }
          &.read-only {
            background: ${COLORS.black200};
            border-color: ${COLORS.black400};
            color: ${COLORS.black400};
          }
          &.is-place-holder-same-style-of-value {
            ::placeholder {
              color: ${COLORS.black900};
            }
          }
          &:focus:valid {
            border: 2px solid ${COLORS.primary};
          }
        }
        .input-icon + input {
          padding-left: 56px;
        }

        &.underline {
          display: flex;
          padding: 14px 0;
          .input-icon {
            position: initial;
            margin-right: 11px;
          }
          input {
            border: none !important;
            padding: 0 !important;
          }
          .round-border {
            border: 1px solid ${COLORS.black900};
            border-radius: 2px;
            position: absolute;
            bottom: 0;
            width: 100%;
          }
        }
      }
    }

    .input-counter-cont {
      margin-top: 4px;
      text-align: right;
    }

    .form-error {
      color: ${COLORS.redError};
      margin-top: 10px;
    }

    .password-toggle-button {
      width: 48px;
      height: 48px;
      position: absolute;
      right: 0;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
    }
    .text-toggle-button {
      width: 24px;
      height: 50px;
      position: absolute;
      right: 16px;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
    }
  }
`;

export default styles;
